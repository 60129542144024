<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="卡号" prop="cardNo">
                <a-input v-model="queryParam.cardNo" placeholder="请输入卡号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="所属主卡卡号" prop="mainCardNo">
                <a-input v-model="queryParam.mainCardNo" placeholder="请输入所属主卡卡号" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="车辆编号" prop="vehicleNo">
                  <a-select
                    show-search
                    allowClear
                    placeholder="请输入车辆自编号模糊查找"
                    :default-active-first-option="false"
                    :show-arrow="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handleVehicleNoSearch"
                    @change="handleVehicleNoChange"
                    :loading="loading"
                    v-model="queryParam.vehicleNo"
                  >
                    <a-select-option v-for="d in vehicleNoArray" :key="d.vehicleNo" :value="d.vehicleNo">
                      {{ d.vehicleNo }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="有效标志" prop="status">
                  <a-radio-group v-model="queryParam.status" button-style="solid">
                    <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{
                      d.dictLabel
                    }}</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>
            </template>

            <a-col :md="(!advanced && 6) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:iot:oilCard:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:iot:oilCard:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['tenant:iot:oilCard:remove']">
            <a-icon type="delete" />删除
          </a-button>
          <a-button type="primary" @click="$refs.importExcel.importExcelHandleOpen()">
            <a-icon type="import" />导入
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:oilCard:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>
      <!-- 增加修改 -->
      <create-form ref="createForm" :isMainOptions="isMainOptions" :statusOptions="statusOptions" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="isMain" slot-scope="text, record">
          {{ isMainFormat(record) }}
        </span>
        <span slot="status" slot-scope="text, record">
          {{ statusFormat(record) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:iot:oilCard:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:iot:oilCard:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:iot:oilCard:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:oilCard:remove']"> <a-icon type="delete" />删除 </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <!-- 上传文件 -->
      <import-excel ref="importExcel" @ok="getList" :sourceForm="this" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import debounce from 'lodash/debounce'
import { listOilCard, delOilCard, exportOilCard, importTemplate, importData } from '@/api/iot/oilCard'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'
import { searchCarNo } from '@/api/iot/vehicleAccount'
export default {
  name: 'OilCard',
  components: {
    CreateForm,
    ImportExcel
  },
  data() {
    this.handleVehicleNoSearch = debounce(this.handleVehicleNoSearch, 500)
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 是否主卡字典
      isMainOptions: [
        {
          dictLabel: '主卡',
          dictValue: '0'
        },
        {
          dictLabel: '分卡',
          dictValue: '1'
        }
      ],
      statusOptions: [
        {
          dictLabel: '有效',
          dictValue: 1
        },
        {
          dictLabel: '无效',
          dictValue: 0
        }
      ],
      statusOptions2: [
        {
          dictLabel: '有效',
          dictValue: '1'
        },
        {
          dictLabel: '无效',
          dictValue: '0'
        }
      ],

      // 查询参数
      queryParam: {
        status: 1,
        cardNo: null,
        mainCardNo: null,
        vehicleNo: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否主卡',
          dataIndex: 'isMain',
          scopedSlots: { customRender: 'isMain' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '卡号',
          dataIndex: 'cardNo',
          ellipsis: true,
          align: 'center'
        },
        // {
        //   title: '所属主卡id',
        //   dataIndex: 'mainCardId',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '所属主卡卡号',
          dataIndex: 'mainCardNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '可用余额',
          dataIndex: 'balanceAvailable',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '总余额',
          dataIndex: 'balanceTotal',
          ellipsis: true,
          align: 'center',
          customRender: (text, record, index) => {
            if (record.isMain === '0') {
              return record.balanceTotal
            } else {
              return '-'
            }
          }
        },
        {
          title: '车辆编号',
          dataIndex: 'vehicleNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '有效标志',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      form: {}
    }
  },
  filters: {},
  created() {
    this.getList()
    // this.getDicts('sys_yes_no').then(response => {
    //   this.isMainOptions = response.data
    // })
  },
  computed: {},
  watch: {},
  methods: {
    importTemplateEx() {
      return importTemplate()
    },
    importDataEx() {
      return importData()
    },
    /** 查询加油卡管理列表 */
    getList() {
      this.loading = true
      listOilCard(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 是否主卡字典翻译
    isMainFormat(row, column) {
      return this.selectDictLabel(this.isMainOptions, row.isMain)
    },
    statusFormat(row, column) {
      console.log('statusFormat', row)
      return this.selectDictLabel(this.statusOptions2, row.status)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      console.log('query', this.queryParam)
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        cardNo: undefined,
        mainCardNo: undefined,
        vehicleNo: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delOilCard(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportOilCard(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    handleVehicleNoSearch(value) {
      console.log('handleVehicleNoSearch', value)
      const queryParam = {
        vehicleNo: value
      }
      this.loading = true
      searchCarNo(queryParam).then((response) => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleVehicleNoChange(value) {
      this.queryParam.vehicleNo = value
    }
  }
}
</script>
